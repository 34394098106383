@import url('./font.css');
* {box-sizing: border-box;}
html,body{padding: 0;margin: 0;font-family: 's',sans-serif;font-size: 14px;}
h1,h2,h3,h4,h5,h6{margin: 0;font-family: 'sb',sans-serif;}
a, input, select, button{box-shadow: none !important}
img,iframe{max-width: 100%;border: 0}
b,strong{font-family: 'sb',sans-serif}
::-webkit-input-placeholder{color:#ccc;font-weight:300;font-size:14px}
::-moz-placeholder{color:#ccc;font-weight:300;font-size:14px}
:-ms-input-placeholder{color:#ccc;font-weight:300;font-size:14px}
:-moz-placeholder{color:#ccc;font-weight:300;font-size:14px}
::-webkit-scrollbar{width:7px;height:5px}
::-webkit-scrollbar-track{background: #ccc}
::-webkit-scrollbar-thumb{background: #191919}
::selection {background: orange !important}
.text-center{text-align: center}
*:focus{outline: none;box-shadow: none;}


/* COSTOM ANTD 
=========================== */
.ant-select-search__field__placeholder, .ant-select-selection__placeholder{font-weight: 300;}
.ant-input,.ant-select,.ant-input-affix-wrapper{font-family: Arial, Helvetica, sans-serif;}
.ant-select-selection__rendered{line-height: initial}
.ant-select-selection--single{height: initial;border-radius: 0;line-height: initial;}
.ant-input-search input[type='text']{height: initial;border-radius: 0;}
.ant-select-dropdown{border-radius: 0}
.ant-select-dropdown-menu-item{border-radius: 0 !important;transition: 0s all !important;font-family: Arial, Helvetica, sans-serif;font-size: 14px}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active{background: #f9f9f9;color: #000;transition: 0s all;;}
.ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab{font-size: 18px;color: #333;background: #c1cfd8;border-radius: 0;line-height: initial;height: initial;padding: 8px 0;width: 160px;text-align: center;border: 0;margin-right: 5px}
.ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active{background: #ff9566;color: white;font-weight: normal;}
.ant-modal-content{box-shadow: none;border-radius: 0}
.ant-modal-close-x{width: initial;height: initial;line-height: 32px;padding-right: 15px;}
.ant-modal-close-x>i{color: #ccc;font-size: 14px;}
.ant-modal-close-x>i:after{content: 'CLOSE';display: inline-block;vertical-align: middle;font-weight: 500;margin-left: 2px;}
.ant-modal-close-x>i>svg{display: inline-block;vertical-align: middle;transform: rotate(0);transition: .2s all}
.ant-modal-close-x>i:hover{color: #777}
.ant-modal-close-x>i:hover>svg{transform: rotate(-90deg);transition: .3s all}
.ant-radio-button-wrapper-focused, .ant-radio-button-wrapper:hover{color: #042955}
.ant-radio-button-wrapper-checked{background: #042955;color: #fff !important;border-color: #042955 !important;box-shadow: none !important;outline: none !important}
.ant-radio-button-wrapper{font-weight: bold;}
.ant-pagination-item-active:focus, .ant-pagination-item-active:hover{border-color: transparent;color: #042955}
.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a,.ant-pagination-item-active,.ant-pagination-item-active a{color: #042955 !important;font-weight: bold;border-color: transparent}
.ant-pagination-item:focus a, .ant-pagination-item:hover a{color: #042955}
.ant-tabs-nav-scroll{text-align: center;}
.ant-tabs{padding: 20px 0}
.ant-tabs-nav .ant-tabs-tab{font-family: s;font-size: 28px;line-height: 38px;color: #333;padding: 0 5px}
.ant-tabs-nav .ant-tabs-tab-active{color: #354C7C;font-family: sb;}
.ant-tabs-bottom .ant-tabs-ink-bar-animated, .ant-tabs-top .ant-tabs-ink-bar-animated{background-color: #042956}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link, .ant-pagination-disabled:focus a, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:hover a, .ant-pagination-disabled a{border: 0}
.ant-collapse>.ant-collapse-item>.ant-collapse-header{font-family: sb;font-size: 26px;color: #032954;background: #E8E8E8;padding: 18px 20px 16px}
.ant-collapse-borderless>.ant-collapse-item{margin-bottom: 7px;}
.ant-collapse>.ant-collapse-item>.ant-collapse-header .arrow{left: initial;right: 10px;font-size: 28px;line-height: 10px;}
.ant-collapse-item-active>.ant-collapse-header{background: #032954 !important;color: #fff !important}
.ant-collapse-borderless>.ant-collapse-item{border: 0}
.collapse-detail{font-family: s;font-size: 26px;line-height: 28px;padding: 15px 0;color: #000}
.ant-modal-title{font-size: 40px;line-height: 40px;font-family: sb;}
.ant-modal-header{border: 0;padding: 40px 30px 0;}
.ant-modal-title p>span{font-size: 14px;font-family: Arial, Helvetica, sans-serif;display: block;line-height: 14px}
.ant-modal-title p>span button{background: #364C7D;font-size: 14px;color: #fff;border: 0;border-radius: 4px;padding: 5px 10px;margin-left: 5px;}
.ant-modal-title p>span button:hover{background: #042955;color: #fff;cursor: pointer;}
.ant-modal-title p{margin: 0}

/* DEFAULT CLASS
=========================== */
.container{width: 1200px;max-width: 100%;margin: 0 auto;padding: 0 15px;display: block;}
@media(max-width: 1199px){
    header #logo img{width: 490px}
    header nav ul>li>a{font-size: 25px;}
    h2.heading{font-size: 65px;}
    main #category>div>div h3{font-size: 38px;line-height: 38px;}
    main #category>div>div h3{padding: 16px 0 5px}
    footer .container>div>div:last-child>div>div>p:not(:last-child){font-size: 24px;}
    footer .container>div>div:last-child>div img{width: 36px}
    footer .container>div>div:last-child>div{grid-template-columns: 45px auto}
    footer .container>div>div:last-child>div>div>p:not(:first-child){display: none;}
    main #about h1{font-size: 80px;}
    header#second-header #nav-logo{display: none;}
    header nav ul>li>a{border-left: 0}
}
@media(max-width: 991px){
    main .detail img{height: initial !important;width: initial !important}
    main #about h1{font-size: 70px;}
    h2.heading{line-height: 65px;font-size: 65px;}
    main #about .container>div{font-size: 30px;}
    main #about{padding-top: 60px;}
    body{padding-top: 107px}
    header #logo{text-align: left;position: fixed;top: 0;padding: 55px 15px 13px;z-index: 998;margin: 0;width: 100%;background: #fff;box-shadow: 0 1px 3px rgba(0,0,0,.3)}
    header #logo img{width: 180px;margin: 0;padding: 0}
    header #topnav{position: fixed;top: 0;left: 0;width: 100%;z-index: 1000}
    header #topnav>.container{padding: 0}
    header nav{position: fixed;top: 0;z-index: 999;background: #fff;width: 270px;right: -280px;height: 100vh;padding-top: 70px;box-shadow: 0 1px 5px rgba(0,0,0,.3);transition: .1s all}
    header.active nav{transition: .3s all;right: 0}
    header nav ul{display: block}
    header nav ul>li{display: block;border: 0;padding: 15px}
    header nav ul>li a{border: 0}
    #responsive-menu{display: inline-block;position: absolute;bottom: 10px;right: 15px;border: 0;padding: 0;background: transparent;font-size: 30px;line-height: 30px;cursor: pointer;}
    #responsive-menu>*{display: inline-block;vertical-align: middle}
    #responsive-menu>span{margin-right: 5px;font-family: sb;font-size: 40px;line-height: 30px;}
    #responsive-menu>i{line-height: 22px}
    header.active #responsive-menu-close{display: inline-block;position: absolute;top: 55px;right: 15px;border: 0;padding: 0;background: transparent;font-size: 30px;line-height: 30px;cursor: pointer;z-index: 100000;}
    main .items{grid-template-columns: 33.33% 33.33% 33.33%}
    main .articles>.item{padding: 0 5px}
    footer .container>div>div:first-child{grid-area: col1;border-right: 1px solid rgba(255,255,255,.3);padding-right: 30px !important;}
    footer .container>div>div:nth-child(2){grid-area: col2;text-align: center;margin-bottom: 15px;}
    footer .container>div>div:nth-child(2) form{text-align: left;}
    footer .container>div>div:nth-child(3){grid-area: col3;padding-left: 30px !important}
    footer .container>div{grid-template-columns: 60% 40%;grid-template-areas: 'col2 col2' 'col1 col3'}
    footer .container>div>div:nth-child(2){border: 0}
    footer .container>div>div{padding: 0 !important}
    main .items>.item{padding: 5px}
    main .items{margin: 0 -5px}
    footer h2>img{width: 400px;max-width: 100%;margin-top: 5px;}
    footer .container>div>div:last-child>div img{padding-top: 15px;}
    footer .ant-form-item-label{margin-top: 5px;}
    main .items>.item:nth-child(7),main .items>.item:nth-child(8){display: none;}
    main #contact-page #wrap-contact>form{grid-area: form}
    main #contact-page #wrap-contact>div{grid-area: detail}
    main #contact-page #wrap-contact>div:last-child{padding-left: 0;}
    main #contact-page #wrap-contact{grid-template-columns: 100%;grid-template-areas: 'detail' 'form'}
    main #item-page #item-similar .items>.item:nth-child(4){display: none;}
    main #item-page #main-item{grid-template-columns: 50% 50%}
    header#second-header #logo{display: block}
    header#second-header #nav-logo{display: none !important;}
    main #cart-page #cart-detail{display: block}
    main #cart-page #cart-detail>div:last-child:not(:first-child){margin-top: 30px;}
    main #cart-page #cart-detail .items.col1{grid-template-columns: 50% 50%;margin: 0 -5px}
    main #cart-page #cart-detail .items.col1>.item{padding: 0 5px}
    main #cart-page table tr>th{white-space: nowrap}
    main #confirm-page #confirm-detail{grid-template-columns: 100%}
    main #confirm-page #confirm-detail>div{margin-bottom: 15px;}
    main #success-page #shipping-detail>div:nth-child(1){padding-right: 15px;}
    main #success-page #shipping-detail>div:nth-child(3){padding-right: 15px;}
    main #success-page{padding-top: 30px;padding-bottom: 30px;}
    main #confirm-page{padding: 30px 0}
    main #cart-page{padding-top: 30px;padding-bottom: 30px;}
    #profile-page{padding: 30px 0 0}
    main #profile-page #search-profile .ant-input-affix-wrapper{max-width: 100%}
    main #profile-page #search-profile label{margin: 0;display: block}
    main #cart-page table .item-detail>div h3{line-height: 28px;}
    main #cart-page table .item-detail{grid-template-columns: 100px auto}
}

@media(max-width:767px){
    main #cart-page table{min-width: 800px}
    main #confirm-page table tbody>tr>td:not(:first-child){white-space: nowrap;text-align: right}
    main #success-page #wrap-button{margin-bottom: 0;margin-top:40px;}
    main #success-page #wrap-button a{display: block;margin: 0 0 15px !important;}
    main #success-page #wrap-button button{display: block;width: 100%;}
    main #success-page #shipping-detail{grid-template-columns: 100%}
    main #success-page #thank{display: block;text-align: center;}
    main #success-page #thank i{margin: 0 0 20px}
    main #success-page #thank>div{padding: 0}
    main #success-page .container>div{padding: 40px 15px}
    main #success-page #shipping-detail>div:nth-child(1){padding-right: 0;}
    main #success-page #shipping-detail>div:nth-child(3){padding-right: 0;}
    body{padding: 0}
    body #root{padding: 62px 0 47px}
    main #category>div{grid-template-columns: auto}
    main .items{grid-template-columns: 50% 50%}
    main .articles{grid-template-columns: 50% 50%}
    main .articles>div:nth-child(3){display: none;}
    footer .container>div{grid-template-columns: auto auto;}
    footer p.phone{font-size: 45px;line-height: 45px;}
    footer p.phone i{font-size: 34px;}
    header #logo{padding-top: 10px;}
    header #topnav{bottom: 0;top: initial;border-top:1px solid rgba(255,255,255,.1)}
    header nav{padding-top: 35px;}
    header.active #responsive-menu-close{top:13px}
    h2.heading{font-size: 60px;line-height: 60px;margin-bottom: 5px;}
    main #heading-page{padding: 30px 10px}
    main #heading-page h1{font-size: 55px;line-height: 55px;}
    .ant-table-content{overflow: hidden;overflow-x: scroll}
    .ant-table-content table thead>tr>th{white-space: nowrap;}
    main #check-page #search-check label{margin: 0}
    main #article-view-page h1{font-size: 50px;line-height: 50px;}
    main #contact-page form .ant-row.ant-form-item{display: block}
    main #contact-page #wrap-contact>div ul>li p:first-of-type>span:last-child{display: none;}
    main #item-page #item-similar .items>.item:nth-child(4){display: block;}
    main #item-page #main-item{grid-template-columns: 100%}
    main #item-page #main-item>div:last-child{padding-left: 0;padding-top: 15px;}
    main #cart-page #wrap-total p>span:last-child{margin: 0 0 0 10px}
    main #cart-page #wrap-total p{text-align: center;}
    main #cart-page #wrap-total h2{text-align: center;}
    main #category>div>div{margin-top: 1px;}
    main .items>.item>div>div>p:first-child{top: -38px}
    main .item>div>div a{width: 100%}
}

@media(max-width:549px){
    header #topnav .container>div>ul:last-child>li:first-child:not(#member-logout-menu){display: none;}
    header #topnav .container>div>ul:last-child>li#member-logout-menu span{display: none;}
    header #topnav .container>div>ul:last-child>li#member-profile-menu span{display: none;}
    footer .container>div{grid-template-areas: 'col2' 'col1' 'col3'}
    footer .container>div>div:nth-child(3){padding-left: 0 !important;margin-top: 20px;}
    footer .container>div>div:nth-child(1){padding-right: 0 !important;border: 0}
    main .articles{grid-template-columns: 100%}
    header #topnav ul:last-child>li:nth-child(2){border-left: 1px solid rgba(255,255,255,.3);border-right: 1px solid rgba(255,255,255,.3)}
    main #about h1{font-size: 60px;line-height: 45px;}
    header #topnav ul>li>a{padding: 3px 7.5px 2px}
    main #payment-page form .ant-row.ant-form-item{grid-template-columns: auto}
    main #payment-page form .ant-form-item-label label{line-height: 26px}
    main #payment-page .ant-input-number, main #payment-page form .ant-time-picker, main #payment-page form .ant-calendar-picker{max-width: 100%}
    main #payment-page form .ant-form-item-label{padding-right: 0;}
    .ant-form-item-label{padding-bottom: 0;}
    main #cart-page #wrap-total>div>a{display: block;margin: 10px 0 0 !important}
    main #cart-page #wrap-total>div>a:first-child{display: none;}
    #register form .ant-row.ant-form-item{grid-template-columns: 100%}
    #register form .ant-form-item-label label{line-height: 26px;}
    .ant-modal-title p>span button{margin: 5px 0 0 5px}
    #login form .ant-row.ant-form-item{grid-template-columns: 100%}
    #login form .ant-form-item-label label{line-height: 26px;}
    #login .wrap-login{margin: 30px 0 15px}
    #login form button[type='submit']{width: 100%;padding: 8px 0 3px}
    .profile form .ant-row.ant-form-item{grid-template-columns: 100%}
    .profile form .ant-form-item-label label{line-height: 26px;}

}

@media(max-width:399px){
    header #topnav .container>div>ul:last-child>li>a>span{display: none;}
}

@media(max-width:349px){
    main .items{grid-template-columns: 100%}
    #responsive-menu>span{display: none;}
    header #topnav .container>div>ul:first-child>li:first-child{display: none;}
}

@media print {
    header,footer{display: none !important}
    main #success-page{padding-top: 0 !important;}
    main #success-page #thank{margin-bottom: 15px !important}
    main #success-page h1{display: none !important}
    #wrap-button{display: none !important}
    main #success-page .container>div{border: 0 !important;box-shadow: none !important;padding: 0 !important}
    #shipping-detail { 
        page-break-after: always; 
      }
      main #success-page h3{background: #f0f0f0;padding: 5px 15px 2px;margin-bottom: 5px !important;}
      main #success-page #thank{border: 0}
      main #success-page #shipping-detail>div>p,main #success-page #shipping-detail>div>div{padding-left: 15px}
      main #success-page #shipping-detail>div{margin-bottom: 0 !important}
}

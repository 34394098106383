@import url("./default.css");
h2.heading{font-size: 70px;color: #364C7D;text-align: center;margin: 0 0 15px}
.primary{color: #fff;background: #364C7D;font-size: 28px;display: inline-block;padding: 12px 20px 7px;border-radius: 4px;line-height: 28px;min-width: 130px;text-align: center;transition: .1s all}
.primary:hover,.primary:focus,.primary:active{color: white;background: #364C7D;transform: translateY(-3px);transition: .3s all;text-decoration: none;}
.primary i{font-size: 20px;}
.wrap-viewmore{text-align: center;padding: 30px 0 40px}
.wrap-viewmore a{background: #354C7C;color: #fff;padding: 11px 20px 7px;line-height: 22px;font-size: 22px;border-radius: 4px;transition: .1s all;display: inline-block;}
.wrap-viewmore a:hover,.wrap-viewmore a:focus,.wrap-viewmore a:active{background:#3466AD;color: #fff;transition: .3s all}
#responsive-menu,#responsive-menu-close{display: none;}
.no-item-found{color:#000;font-family: Arial, Helvetica, sans-serif;font-size: 13px;padding:50px 0;background: #f0f0f0;text-align: center;}
#out-of-stock{color:red;}
#cart-not-found{font-family: sb;font-size: 24px;color:#333;padding: 20px 0 0 20px;background: #f0f0f0;height: 350px;text-align: center;margin-right: 15px;}
.btn-delete-item{cursor: pointer;}
.alert-message{color: #c33;margin:0}

/* #login form .ant-form-item-label{display: grid;grid-template-columns: 120px auto} */
#login form .ant-row.ant-form-item{display: grid;grid-template-columns: 140px auto}
#login form .ant-row.ant-form-item:before,#login form .ant-row.ant-form-item:after{display: none;}
#login form .ant-form-item-label label{font-size: 26px;color: #333;font-family: s;padding-right: 20px;}
#login form .ant-time-picker-input, #login form .ant-input{height: initial;padding: 10px 10px 10px 32px}
#login form button[type='submit']{background: #364C7D;color: #fff;border: 0;padding: 10px 20px 6px;font-family: sb;font-size: 26px;height: initial;}
#cart-balloon{background: red; color: white; font-size: 11px !important; font-family: Arial, Helvetica, sans-serif; position: absolute; line-height: initial !important; padding: 2px 2px; min-width: 20px; text-align: center; border-radius: 4px; top: 3px; right: -6px;}
#login .wrap-login{text-align: center;margin: 40px 0;}

#register{padding-top: 30px;border-top: 1px solid #ccc}
#register form .ant-row.ant-form-item{display: grid;grid-template-columns: 140px auto}
#register form .ant-row.ant-form-item:before,#register form .ant-row.ant-form-item:after{display: none;}
#register form .ant-form-item-label label{font-size: 26px;color: #333;font-family: s;padding-right: 20px;}
#register form .ant-input{height: initial;padding: 10px}
#register form input[type='text'],#register form input[type='email'],#register form input[type='password']{padding-left: 32px}
#register form button[type='submit'],#register form button[type='button']{background: #364C7D;color: #fff;border: 0;padding: 7px 20px 3px;font-family: sb;font-size: 26px;height: initial;border: 1px solid transparent}
#register form button[type='submit']:hover{background: #042955;}
#register form button[type='button'] i{font-size: 14px;}
#register form button[type='button']{border-radius: 4px;padding: 7px 20px 3px;cursor: pointer;}
#register form button[type='button']:hover{background: #042955;}
#register form .wrap-next{text-align: right}
#register form textarea{min-height: 90px}
#register .wrap-register{margin: 40px 0;text-align: center}
#register .wrap-register button[type='button']{background: #e0e0e0;color: #000}
#register .wrap-register button[type='button']:hover{background: #e9e9e9;color: #000;border: 1px solid #ccc}
#register .wrap-register button[type='button'] i{margin-right: 5px;}
#register h3{font-size: 26px;line-height: 26px;background: #eee;padding: 10px 15px 5px;box-shadow: 0 1px 3px rgba(0,0,0,.3);margin-bottom: 30px;}
#register .dn{display: none;}
#register .ant-select-selection--single{padding: 12px 0;border-radius: 4px}
#shipping-info b{font-family: Arial, Helvetica, sans-serif}
#shipping-info p{margin-bottom: 0;padding: 10px;background: #eee;margin-right: 30px}

#profile-page{padding: 40px 0}
.profile{width: 800px;margin: 30px auto;max-width: 100%}
.profile form .ant-row.ant-form-item{display: grid;grid-template-columns: 180px auto}
.profile form .ant-row.ant-form-item:before,.profile form .ant-row.ant-form-item:after{display: none;}
.profile form .ant-form-item-label label{font-size: 26px;color: #333;font-family: s;padding-right: 20px;}
.profile form .ant-input{height: initial;padding: 10px}
.profile form input[type='email'],.profile form input[type='password']{padding-left: 32px}
.profile form button[type='submit'],.profile form button[type='button']{background: #364C7D;color: #fff;border: 0;padding: 7px 20px 3px;font-family: sb;font-size: 26px;height: initial;border: 1px solid transparent}
.profile form button[type='submit']:hover{background: #042955;}
.profile form button[type='button'] i{font-size: 14px;}
.profile form button[type='button']{border-radius: 4px;padding: 7px 20px 3px;cursor: pointer;}
.profile form button[type='button']:hover{background: #042955;}
.profile form .wrap-next{text-align: right}
.profile form textarea{min-height: 90px}
.profile .wrap-profile{margin: 40px 0;text-align: center}
.profile .wrap-profile button[type='button']{background: #e0e0e0;color: #000}
.profile .wrap-profile button[type='button']:hover{background: #e9e9e9;color: #000;border: 1px solid #ccc}
.profile .wrap-profile button[type='button'] i{margin-right: 5px;}
.profile h3{font-size: 26px;line-height: 26px;background: #eee;padding: 10px 15px 5px;box-shadow: 0 1px 3px rgba(0,0,0,.3);margin-bottom: 30px;}
.profile .dn{display: none;}
.profile .ant-select-selection--single{padding: 12px 0;border-radius: 4px}

header ul{margin: 0;padding: 0;list-style: none;}
header #topnav{background: #042955;}
header #topnav ul>li{display: inline-block;vertical-align: middle;}
header #topnav ul>li>a{color: #fff;padding: 2px 7.5px;display: inline-block;transition: .1s all;font-size: inherit;font-size: 28px;position: relative;}
header #topnav ul>li>a:hover{background: #fff;color: #042955;transition: .3s all}
header #topnav div>ul:last-child>li:last-child>a:hover{background: rgba(255,255,255,.5)}
header #topnav ul>li>a>*{display: inline-block;vertical-align: middle;}
header #topnav ul>li>a>span{font-size: inherit;line-height: inherit;margin-top: 4px;line-height: 0;margin-left: 5px;}
header #topnav .container>div{display: grid;grid-template-columns: auto max-content;}
header #topnav .container>div>ul:last-child>li>a{padding: 2px 15px}
header #logo{text-align: center;margin: 35px 0}
header nav{border-top: 1px solid #ccc;border-bottom: 1px solid #ccc}
header nav ul{display: grid;grid-template-columns: auto auto auto auto auto auto auto auto}
header nav ul>li{display: inline-block;text-align: center;padding: 20px 0}
header nav ul>li>a{font-size: 30px;color: #333;display: block;padding: 4px 10px 0;border-right: 1px solid #ccc;line-height: 20px;font-family: 'sb';border-left: 1px solid #eee}
header nav ul>li>a:hover,header nav ul>li>a:focus,header nav ul>li>a:active,header nav ul>li.active>a{color: #042955;text-decoration: underline}
header nav ul>li:last-child>a{border-right: 0}
header nav ul>li:first-child>a{border-left: 0}
header nav ul>li:nth-child(2)>a{border-left: 0}
header #nav-logo{display: none;padding:6px 0}
header #nav-logo>a{border: 0;}
header#second-header{box-shadow: 1px 3px 15px rgba(0,0,0,.3)}
header#second-header #logo{display: none;}
header#second-header #nav-logo{display: inline-block}
header#second-header #nav-logo img{width: 150px}
header#second-header nav ul>li>a{font-size: 24px;}
header#second-header nav ul{grid-template-columns: auto auto auto auto auto auto auto auto auto}


main #about{background: #042955;color: white;text-align: center;padding: 80px 0 50px;margin-top: -5px}
main #about h1{color: white;font-size: 90px;margin: 0 0 30px}
main #about .container>div{margin: 0 0 5px;line-height: 40px;font-size: 35px;}
main #about .container>div{width: 800px;margin: 0 auto;max-width: 100%;margin-bottom: 20px;}
main #category{padding-top: 30px;}
main #category>div{display: grid;grid-template-columns: 50% 50%}
main #category>div>div a{display: block;position: relative;}
main #category>div>div a img{width: 100%}
main #category>div>div h3{position: absolute;bottom: 0;left: 0;text-align: center;width: 100%;background: rgba(3,41,84, .4);padding: 22px 0 10px;color: #fff;font-size: 45px;line-height: 45px;transition: .2s all;}
main #category>div>div h3 span{display: block;}
main #category>div>div a:after{content: '';width: 100%;height: 0;background: rgba(3,41,84,.4);padding: 0;left: 0;top: 100%;transition: .1s all;position: absolute;opacity: 0;}
main #category>div>div a:hover{background: transparent;}
main #category>div>div a:hover:after{height: 100%;top: 0;left: 0;transition: .3s all;opacity: 1;}
main #category>div>div a:hover h3{z-index: 1;background: transparent;bottom: 40%;transition: .3s all}
main #items{background:#E8E8E8;padding-top: 40px;}
main .items{display: grid;grid-template-columns: 25% 25% 25% 25%;margin: 0 -7.5px;font-family: s;}
main .items.col1{grid-template-columns: auto;margin: 0}
main .items.col1>.item{padding: 0;margin-bottom: 15px}
main .items>.item{padding: 7.5px}
main .items>.item>div{background: #fff;border: 2px solid #ccc;box-shadow: 0 1px 5px rgba(0,0,0,.2);display: block;padding-bottom: 15px;}
main .items>.item>div:hover{border: 2px solid rgba(53,76,124,.75);box-shadow: 0 1px 5px rgba(53,76,124,.3)}
main .items>.item>div>div{padding: 10px;color: #000;font-size: 22px;line-height: 22px;position: relative;}
main .items>.item>div>div p{font-size: inherit;line-height: inherit;margin-bottom: 0;}
main .items>.item>div>div>p:first-child{display: inline-block;position: absolute;right: 10px;top:5px;padding: 4px 7.5px 1px;background: #354C7C;color: white;border-radius: 4px}
main .items>.item>div>div p.item-description{max-height: 44px;overflow: hidden;}
main .items>.item>div>div p.item-brand{white-space: nowrap;overflow: hidden;text-overflow: ellipsis}
main .items>.item>div:hover>div>p:first-child{background: #3466AD;}
main .items>.item>div:hover a.view{background:#3466AD;color: #fff;transition: .3s all}
main .items>.item>div>div>div{margin-top: 15px;text-align: center;}
main .items>.item>div.wholesale>div>p:first-child{background: orange}
main .items>.item>div.wholesale>div p:nth-child(2){color: orange;font-family: sb;}
main a.view{background: #354C7C;color: #fff;padding: 9px 15px 3px;line-height: 22px;font-size: 22px;border-radius: 4px;transition: .1s all;display: inline-block;}
main a.view:hover{transform: translateY(-3px)}
main a.view>i{margin-right: 7.5px;}
main a.view>*{display: inline-block;vertical-align: middle;font-size: inherit;line-height: inherit;}
main #articles{padding-top: 40px;background: #fff;padding-bottom: 60px;}
main .articles{display: grid;grid-template-columns: 33.33% 33.33% 33.33%;margin: 0 -15px}
main .articles>.item img{width: 100%}
main .articles>.item{padding: 0 15px 15px;margin-bottom: 10px;}
main .articles>.item>div{background: #fff;border: 1px solid #ccc;display: block;padding: 20px 15px}
main .articles>.item>div:hover{border-color: rgba(3,41,84,.7);box-shadow: 0 0px 1px 1px rgba(3,41,84,.9)}
main .articles>.item>div>div{padding: 20px 0 0;color: #000;font-size: 22px;line-height: 22px}
main .articles>.item h3{text-align: center;}
main .articles>.item h3>a{font-size: 34px;line-height: 34px;color: #354C7C;}
main .articles>.item h3>a:hover{text-decoration: underline}
main .articles>.item .date{font-size: 13px;color: #777;text-align: center;font-family: Arial;}
main .articles>.item .description{font-size: 25px;line-height: 25px;text-align: center;color: #000}
main .articles>.item>div>div>div{text-align: center;}
main .item>div>div a{padding: 9px 15px 6px;}
main #heading-page{background: url('/img/upload/banner-heading.jpg') no-repeat center center;background-size: cover;background-attachment: fixed;padding: 70px 0;text-align: center;}
main #heading-page h1{font-size: 70px;color: #042956}
main .detail{padding-bottom: 50px;font-family: s,sans-serif !important;color: initial;}
main .detail *{font-family: s !important}
main .detail p{margin: 0}
main .detail h1,main .detail h2,main .detail h3,main .detail h4,main .detail h5,main .detail h6, main .detail b, main .detail strong{font-family: sb !important;}
main .ant-breadcrumb{padding: 30px 0 15px}
main .ant-breadcrumb a,main .ant-breadcrumb span{font-family: sb;font-weight: bold;font-size: 22px;line-height: 22px;color: #999}
main .ant-breadcrumb a{color: #354C7C}
main .ant-breadcrumb a:hover{text-decoration: underline;color: #042956}
main .wrap-size{margin: 20px 0;text-align: center;}
main .wrap-pagination{margin: 20px 0;text-align: center;}
main #payment-page form{width: 850px;max-width: 100%;margin: 0 auto;padding-top: 30px;}
main #payment-page form .ant-form-item-label{padding-right: 20px;}
main #payment-page form .ant-form-item-label label{font-size: 26px;color: #333;font-family: s;}
main #payment-page form .ant-row.ant-form-item{display: grid;grid-template-columns: 200px auto}
main #payment-page form .ant-row.ant-form-item:before,main #payment-page form .ant-row.ant-form-item:after{display: none;}
main #payment-page form .ant-time-picker-input, main #payment-page form .ant-input{height: initial;padding: 10px}
main #payment-page .ant-input-number, main #payment-page form .ant-time-picker,main #payment-page form .ant-calendar-picker{width: 280px}
main #payment-page .ant-input-number{height: initial;padding: 5px;}
main #payment-page form .ant-input-number+.ant-form-text{display: block;font-family: s;font-size: 22px;color: #444}
main #payment-page form input[type='file']{width: 280px;}
main #payment-page form button{background: #364C7D;color: #fff;font-size: 26px;font-family: sb;padding: 7px 20px 3px;height: initial;border: 0}
main #payment-page form button>*{display: inline-block;vertical-align: middle;font-size: 26px;line-height: 26px}
main #payment-page form button:hover{background: #042956;}
main #payment-page form button[type='button']{background: #999;cursor: default}
main #payment-page form .wrap-submit{padding-top: 20px;margin-bottom: 20px;}
main #check-page .ant-table-thead>tr>th{font-family: sb;font-size: 26px;}
main #check-page .ant-table-tbody>tr>td{font-size: 24px;font-family: s;color: #000;line-height: 20px;}
main #check-page .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,main #check-page .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,main #check-page .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,main #check-page .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td{background: #d8e4ff;}
main #check-page #search-check{padding: 15px 0 30px}
main #check-page #search-check label{font-size: 28px;color: #000;font-family: sb;display: inline-block;vertical-align: middle;margin-bottom: 4px;margin-right: 50px;}
main #check-page #search-check label>span{display: inline-block;vertical-align: middle}
main #check-page #search-check .ant-input-search input[type='text']{border-radius: 7.5px;border: 1px solid #ccc;font-size: 20px;padding: 10px 20px;}
main #check-page #search-check .ant-input-affix-wrapper{width:400px !important;max-width:100%}
main #faqs-page{padding-bottom: 50px;}
main #contact-page form{width: 850px;max-width: 100%;margin: 0 auto;padding-top: 30px;}
main #contact-page form .ant-form-item-label{padding-right: 20px;}
main #contact-page form .ant-form-item-label label{font-size: 26px;color: #333;font-family: s;}
main #contact-page form .ant-row.ant-form-item{display: grid;grid-template-columns: 200px auto}
main #contact-page form .ant-row.ant-form-item:before,main #contact-page form .ant-row.ant-form-item:after{display: none;}
main #contact-page form .ant-time-picker-input, main #contact-page form .ant-input{height: initial;padding: 10px}
main #contact-page .ant-input-number, main #contact-page form .ant-time-picker,main #contact-page form .ant-calendar-picker{width: 280px}
main #contact-page .ant-input-number{height: initial;padding: 5px;}
main #contact-page form .ant-input-number+.ant-form-text{display: block;font-family: s;font-size: 22px;color: #444}
main #contact-page form input[type='file']{width: 280px;}
main #contact-page form button{background: #364C7D;color: #fff;font-size: 26px;font-family: sb;padding: 7px 20px 3px;height: initial;border: 0}
main #contact-page form button>*{display: inline-block;vertical-align: middle;font-size: 26px;line-height: 26px}
main #contact-page form button:hover{background: #042956;}
main #contact-page form button[type='button']{background: #999;cursor: default;}
main #contact-page form .wrap-submit{padding-top: 20px;margin-bottom: 20px;}
main #contact-page #wrap-contact{display: grid;grid-template-columns: 50% 50%;vertical-align: top}
main #contact-page #wrap-contact>div:last-child{padding-left: 50px;}
main #contact-page #wrap-contact>div h2{font-size: 34px;color: #333;font-family: sb;line-height: 40px;border-bottom: 1px solid #ccc;padding-bottom: 10px;margin-bottom:20px;margin-top: 24px;}
main #contact-page #wrap-contact>div p{font-size: 28px;color: #333;line-height: 32px;margin: 0 0 10px}
main #contact-page #wrap-contact>div ul{margin: 25px 0 0;padding: 0}
main #contact-page #wrap-contact>div ul>li{display: grid;grid-template-columns: 67px auto;align-items: center}
main #contact-page #wrap-contact>div ul>li p{margin: 0}
main #contact-page #wrap-contact>div ul>li:first-child span{font-family: sb;}
main #contact-page #wrap-contact>div ul>li a{color: inherit;margin-left: 7px;}
main #contact-page #wrap-contact>div ul>li a:hover{color: #364c7d;text-decoration: underline}
main #contact-page #wrap-contact>div ul>li img{width: 48px;margin-bottom: 20px;}
main #contact-page #wrap-contact>div ul>li span{font-size: 26px;line-height: 24px;;}
main #contact-page #wrap-contact>div ul>li p:first-of-type span{display: block;margin: 0}
main #contact-page #wrap-contact>div ul>li p:first-of-type{margin-bottom: 22px;}
main #contact-page #wrap-contact>div ul>li p:not(:first-of-type){margin-bottom: 10px;}
main #contact-page form textarea{min-height: 140px}
main #contact-page .tel a{color: inherit}
main #contact-page .tel a:hover{text-decoration: underline}
main #item-page #main-item{display: grid;grid-template-columns: 60% 40%}
main #item-page #main-item .ant-carousel .slick-dots{bottom: 40px}
main #item-page #main-item>div:last-child{padding-left: 30px;}
main #item-page #main-item p{font-size: 30px;margin-bottom: 0;color:#000;line-height: 38px}
main #item-page #main-item .qty{margin: 20px 0}
main #item-page #main-item .qty span{background: #eee;padding: 3px 15px 1px;display: inline-block;font-family: sb;}
main #item-page #main-item .price{font-family: sb;color:#354C7C;font-size: 42px;margin-bottom: 40px;}
main #item-page #main-item>div:last-child>div{text-align: center}
main #item-page #main-item>div:last-child>div a.view{font-size: 28px;}
main #item-page h1{font-size: 42px;margin-bottom: 10px;line-height: 42px;}
main #item-page h2{font-size: 34px;}
main #item-page h3{font-size: 34px;}
main #item-page #item-detail{padding: 30px 0;border-top: 2px solid #ccc;margin-top: 30px;}
main #item-page #item-detail .detail{font-size: 26px;line-height: 30px;color: #000}
main #item-page #item-similar{border-top: 2px solid #ccc;padding: 30px 0;margin-top: 30px}
main #article-view-page h1{font-size: 60px;color: rgb(53, 76, 124);line-height: 60px;margin: 15px 0 0;}
main #article-view-page h1+p{font-size: 22px;color: #354C7C;font-family: sb;margin: -5px 0 20px}
main #article-view-page #article-similar{padding: 30px 0;margin: 30px 0;border-top: 2px solid #ccc;}
main #article-view-page #article-similar h2{font-size: 34px;}
main #cart-page{padding: 50px 0}
main #cart-page #cart-detail{display: grid;grid-template-columns: auto 280px}
main #cart-page #cart-detail>div:first-child{padding-right: 15px}
main #cart-page #cart-detail table tr>td:not(:first-child){white-space: nowrap}
main #cart-page #cart-detail table tr>th:not(:first-child){white-space: nowrap;}
main #cart-page h1{font-size: 42px}
main #cart-page table{border: 1px solid #ccc}
main #cart-page table tr>th{font-family: sb;font-size: 28px;text-align: right}
main #cart-page table tr>th:first-child{text-align: left}
main #cart-page table tr>th:nth-child(3){text-align: center}
main #cart-page table .item-detail{display: grid;grid-template-columns: 150px auto}
main #cart-page table .item-detail>div{padding-left: 20px;}
main #cart-page table .item-detail>div h3{font-size: 26px;margin: 10px 0 0}
main #cart-page table .item-detail>div p{font-family: Arial, Helvetica, sans-serif;margin: 0;font-size: 13px;color: #666;margin-bottom: 5px}
main #cart-page table tbody>tr>td:not(:first-child){text-align: right}
main #cart-page table tbody>tr>td:nth-child(3){text-align: center;}
main #cart-page table tbody>tr>td{font-weight: bold;}
main #cart-page #wrap-clear-button{text-align: right;padding: 10px 0;margin-top: 0;border-top: 1px solid #ccc;}
main #cart-page #wrap-clear-button button{font-size: 14px;font-family: Arial, Helvetica, sans-serif;border: 0;padding: 0;display: inline;font-weight: bold;color: #E72732;cursor: pointer;}
main #cart-page #wrap-clear-button button:hover span{text-decoration: underline;color: red;}
main #cart-page #wrap-clear-button button:hover i{color: red}
main #cart-page h2{font-size: 34px;text-align: center;}
main #cart-page .delete-item{font-size: 14px;display: inline-block;margin: 0 15px;cursor: pointer;}
main #cart-page .delete-item:hover{color: #c33}
main #cart-page #wrap-total{border: 1px solid #ccc;box-shadow: 0 0px 1px 1px #eee;padding: 25px 30px 35px}
main #cart-page #wrap-total>div{text-align: right;}
main #cart-page #wrap-total>div>a:first-child{margin-right: 10px;}
main #cart-page #wrap-total h2{text-align: left;margin:0}
main #cart-page #wrap-total p{text-align: right;font-size: 30px;font-family: sb;margin-bottom: 10px;color: #000}
main #cart-page #wrap-total p>span{display: inline-block;vertical-align: middle;}
main #cart-page #wrap-total p>span:last-child{margin: 0 50px;}
main #cart-page .note{font-family: Arial, Helvetica, sans-serif;font-size: 13px;color: #666;margin: 10px}
main #cart-page .ant-input-number-input{text-align: center;}
main #confirm-page{padding: 50px 0}
main #confirm-page h1{font-size: 42px;}
main #confirm-page h2{font-size: 36px;margin-bottom: 7px;}
main #confirm-page #confirm-detail{display: grid;grid-template-columns: 33.33% 33.33% 33.33%;align-items: flex-start;margin: 0 -7.5px}
main #confirm-page #confirm-detail>div{padding: 0 7.5px}
main #confirm-page #confirm-detail>div:first-child>div{border: 1px solid #ccc;box-shadow: 0 0 1px 1px #eee;padding: 30px;}
main #confirm-page #confirm-detail>div:last-child>div{border: 1px solid #ccc;box-shadow: 0 0 1px 1px #eee;padding: 30px ;}
main #confirm-page label{font-weight: bold;}
main #confirm-page .ant-form-item-label{line-height: initial;margin-bottom: 5px;}
main #confirm-page .ant-input{height: initial;padding: 7px;box-shadow: 0 0 0 3px #f0f0f0 !important}
main #confirm-page .ant-select-selection{height: initial;padding: 10px 0;box-shadow: 0 0 0 3px #f0f0f0 !important;border-radius: 4px}
main #confirm-page .note{font-size: 13px;color: #666;margin: -15px 0 15px}
main #confirm-page #confirm-bank p{margin: 0 0 5px;font-weight: normal}
main #confirm-page #confirm-bank em{color: red;font-size: 18px;font-family: Arial, Helvetica, sans-serif;line-height: 14px}
main #confirm-page #confirm-bank p>b{font-family: Arial, Helvetica, sans-serif;color: #333;}
main #confirm-page #confirm-bank>div>div{padding: 30px;border: 1px solid #ccc;box-shadow: 0 0 1px 1px #eee;margin-bottom: 15px}
main #confirm-page #confirm-bank h2+p{font-weight: bold;color: #000}
main #confirm-page #confirm-bank ul{padding: 0 0 0 20px;margin: 0;}
main #confirm-page #confirm-bank ul>li>span{display: block;}
main #confirm-page #confirm-bank #shipping-channel{border-top: 2px solid #ccc;margin: 20px 0 0;padding: 15px 0}
main #confirm-page #confirm-bank #shipping-data{margin-bottom: 15px}
main #confirm-page #confirm-bank #shipping-data>p{display: grid;grid-template-columns: auto 50px 50px;font-weight: bold;}
main #confirm-page #confirm-bank #shipping-data>p>span:not(:first-child){text-align: right;}
main #confirm-page #confirm-summary h2+p{font-weight: bold;color:#333;}
main #confirm-page #confirm-summary thead>tr>th{font-weight: bold}
main #confirm-page #confirm-summary tbody>tr>td{font-weight: bold}
main #confirm-page #confirm-summary thead>tr>th:first-child{text-align: center;}
main #confirm-page #confirm-summary tbody>tr:last-child>td{border-bottom: 0}
main #confirm-page #confirm-summary .item-detail{text-align: center}
main #confirm-page #confirm-summary .item-detail img{height: 80px;}
main #confirm-page #confirm-summary .item-detail h3{font-size: 12px;font-family: Arial, Helvetica, sans-serif;font-weight: bold;margin: 5px 0}
main #confirm-page #confirm-summary #edit-cart{border-bottom: 2px solid #eee;padding-bottom: 3px;}
main #confirm-page #confirm-summary #edit-cart a{color: #333;font-size: 12px;font-weight: bold;}
main #confirm-page #confirm-summary #edit-cart a:hover{text-decoration: underline}
main #confirm-page #confirm-summary table tr>td:not(:first-child){white-space: nowrap;text-align: right;}
main #confirm-page #confirm-summary table tr>th:not(:first-child){white-space: nowrap;text-align: right;}
main #confirm-page textarea{min-height: 110px}
main #confirm-page #confirm-summary #summary{margin-bottom: 20px;}
main #confirm-page #confirm-summary #summary>p:first-child{border-top: 2px solid #ccc;}
main #confirm-page #confirm-summary #summary>p:last-child{font-size: 18px;}
main #confirm-page #confirm-summary #summary p{border-bottom: 2px solid #ccc;display:grid;grid-template-columns: auto max-content;font-weight: bold;margin: 0;padding: 10px}
main #confirm-page #confirm-summary #summary p>span:last-child{text-align: right;}
main #confirm-page button{font-family: sb;height: initial;}
main #confirm-page button i{color: #fff}
main #confirm-page button[type="button"]{cursor: default;background: #999;border-color: #999}
main #success-page{padding: 50px 0}
main #success-page h1{font-size: 42px;}
main #success-page table{border: 1px solid #ccc}
main #success-page table tr>th{font-family: sb;font-size: 28px;text-align: right}
main #success-page table tr>th:first-child{text-align: left}
main #success-page table tr>th:nth-child(3){text-align: center}
main #success-page table .item-detail{display: grid;grid-template-columns: 150px auto}
main #success-page table .item-detail>div{padding-left: 20px;}
main #success-page table .item-detail>div h3{font-size: 26px;margin: 10px 0 0}
main #success-page table .item-detail>div p{font-family: Arial, Helvetica, sans-serif;margin: 0;font-size: 13px;color: #666;margin-bottom: 5px}
main #success-page table tbody>tr>td:not(:first-child){text-align: right}
main #success-page table tbody>tr>td:nth-child(3){text-align: center;}
main #success-page table tbody>tr>td{font-weight: bold;}
main #success-page .container>div{padding: 40px;border: 1px solid #ccc;box-shadow: 0 0 1px 0 #f0f0f0}
main #success-page #thank{display: grid;grid-template-columns: max-content auto;align-items: center;padding-bottom: 30px;border-bottom: 2px solid #eee;margin-bottom: 30px;}
main #success-page #thank>div{padding-left: 15px}
main #success-page #thank i{font-size: 60px;color: #006633}
main #success-page #thank h2{font-size: 40px;margin: 0;line-height: 40px;}
main #success-page #thank h2+p{font-size: 28px;font-family: sb;margin: 0;line-height: 28px;}
main #success-page h3{font-family: sb;font-size: 30px;}
main #success-page p{font-weight: normal;font-family: Arial, Helvetica, sans-serif;color: #666}
main #success-page p>span:first-child{color:#333;font-weight: bold;}
main #success-page #shipping-detail h3+div{font-weight: normal;font-family: Arial, Helvetica, sans-serif;color: #666}
main #success-page #shipping-detail{display: grid;grid-template-columns: 50% 50%}
main #success-page #shipping-detail>div{margin-bottom: 20px}
main #success-page #summary>div{margin-top: 20px;}
main #success-page #summary>div>p{display: grid;grid-template-columns: auto max-content;text-align: right;font-size: 30px;font-family: sb;margin:0;border-bottom: 1px solid #999}
main #success-page #summary>div>p>span:last-child{min-width: 200px}
main #success-page #note{font-size: 13px;color: #999;margin: 10px}
main #success-page #note p{margin: 0}
main #success-page #wrap-button{text-align: center;margin: 50px 0 30px}
main #success-page #wrap-button a:not(:last-child){margin-right: 15px}
main #success-page #wrap-button button i{margin-right: 5px}
main #success-page #wrap-button  button{border: 0;cursor: pointer;}
main #profile-page .ant-table-thead>tr>th{font-family: sb;font-size: 26px;}
main #profile-page .ant-table-tbody>tr>td{font-size: 24px;font-family: s;color: #000;line-height: 20px;}
main #profile-page .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,main #profile-page .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,main #profile-page .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,main #profile-page .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td{background: #d8e4ff;}
main #profile-page #search-profile{padding: 15px 0 30px}
main #profile-page #search-profile label{font-size: 28px;color: #000;font-family: sb;display: inline-block;vertical-align: middle;margin-bottom: 4px;margin-right: 50px;}
main #profile-page #search-profile label>span{display: inline-block;vertical-align: middle}
main #profile-page #search-profile .ant-input-search input[type='text']{border-radius: 7.5px;border: 1px solid #ccc;font-size: 20px;padding: 10px 20px;}
main #profile-page #search-profile .ant-input-affix-wrapper{width:400px !important}
main #profile-page #history table tr>th:not(:nth-child(2)){width: 1%;white-space: nowrap;text-align: center;}
main #profile-page #history table tr>td:not(:nth-child(2)){width: 1%;white-space: nowrap;text-align: center;}
main #profile-page #history table tr>td:nth-child(3){text-align: right;}
main #profile-page #history .history-expend{display: grid;grid-template-columns: 90px auto;vertical-align: top;margin-bottom: 15px;border-bottom: 1px solid #ccc;padding-bottom: 15px;padding-top: 5px;}
main #profile-page #history .history-expend>div{padding-left: 20px}
main #profile-page #history .history-expend>div p{margin: 0 0 2px;font-family: Arial, Helvetica, sans-serif;font-size: 13px;color: #777;}
main #profile-page #history .history-expend>div>p:first-of-type{font-size: 16px;font-weight: bold;}

footer{background: #364C7D;;}
footer h1{font-size: 30px;color:#fff;line-height: 32px;margin-bottom: 15px;}
footer h1+p{font-size: 26px;color: rgba(255,255,255,.75);line-height: 27px;margin-bottom: 15px;}
footer p.phone{font-size: 55px;color:#fff;margin-bottom: 0;line-height: 55px}
footer p.phone a{color: inherit;text-decoration: none;}
footer p.phone i{font-size: 45px;margin-right: 10px;}
footer .container{padding-top: 15px;padding-bottom: 15px;}
footer .container>div{display: grid;grid-template-columns: 33.33% 33.33% 33.33%}
footer .container>div>div{padding-top: 20px;padding-bottom: 20px;;}
footer .container>div>div:first-child{padding-right: 20px;}
footer .container>div>div:last-child{padding-left: 20px;}
footer .container>div>div:nth-child(2){border-right: 1px solid rgba(255,255,255,.5);border-left: 1px solid rgba(255,255,255,.5);padding: 20px}
footer>p{background: #042955;padding: 10px;text-align: center;color: white;font-size: 20px;margin-bottom: 0;font-family: sb;}
footer>p>a{color: inherit}
footer .ant-form-item-label{line-height: 22px;margin-top: 30px;}
footer .ant-form-item-label label{font-size: 28px;color: white;font-family: sb;margin-bottom: 0;line-height: 28px;padding: 0}
footer .ant-input{height: initial;border-radius: 0;padding: 18px 15px;font-size: 16px;}
footer .ant-form-item-label label:before,footer .ant-form-item-label label:after{display: none;}
footer form{display: grid;grid-template-columns: auto max-content;align-items: flex-end}
footer .ant-btn{height: initial;padding: 17.5px;color: white;font-size: 18px;border-radius: 0;background: #042955;border-color: #042955}
footer h3{font-size: 30px;line-height: 30px;color: white;margin-bottom: 5px;}
footer .container>div>div:last-child>div{display: grid;grid-template-columns: 60px auto;align-items: flex-end}
footer .container>div>div:last-child>div p{font-size: 22px;color: #fff;line-height: 20px;margin: 0;font-family: sb;}
footer .container>div>div:last-child>div>div>p:not(:last-child){font-size: 38px;line-height: 28px;font-family: sb;}
footer .container>div>div:last-child>div a{font-size: 22px;color: #fff;line-height: 22px;margin-left: 5px;}
footer .container>div>div:last-child>div img{margin: 0 0 3px;border: 0;padding-top: 20px;}